<template>
  <div class="me-tag-input">
    <Header>
      <i slot="left-one" class="me-icon-back" @click="$router.go(-1)"></i>
      <i slot="right-one" class="me-icon-check" @click="handlerComplateTag()"></i>
    </Header>
    <div class="me-tag-input__content">
      <ul v-if="tags.length">
        <template  v-for="(tag, index) in tags">
        <li v-if="tag.trim()" :key="index">
          {{tag}}
          <i class="me-icon-close" @click="handlerRemoveTag(index)"></i>
        </li>
        </template>
      </ul>
      <input
        type="text"
        v-model="newTag"
        ref="tagInput"
        v-focus="allwaysTrue"
        @keyup.enter="handlerCreateNewTag()"
        @blur="handlerCreateNewTag()"
      />
    </div>
  </div>
</template>
<script>
import Header from "@/components/business/h5/header";
export default {
  name: "tag-input",
  components: {
    Header
  },
  data() {
    return {
      tags: [],
      newTag: "",
      allwaysTrue: true
    };
  },
  created() {
    this.tags = this.$store.state.tags
    console.log(this.tags,'/tag');
    
  },
  methods: {
    handlerComplateTag() {
      this.tags.map((el,index)=>{
        if(!el.trim()) this.tags.splice(index,1)
      })
      this.$store.dispatch("comminTags", this.tags);
      localStorage.setItem("publish-tags", this.tags.join(","));
      localStorage.setItem('changeTag',true)
      this.$router.go(-1);
    },
    handlerCreateNewTag() {
      if (this.newTag.trim() && !this.tags.includes(this.newTag.trim())) {
        this.tags.push(this.newTag.trim());
        this.newTag = "";
        this.$refs.tagInput.focus();
      }
    },
    handlerRemoveTag(index) {
      this.tags.splice(index, 1);
      this.$refs.tagInput.focus();
    }
  }
};
</script>
<style lang="stylus" scoped>
@import '~styles/utils/index';

+b(me-tag-input) {
  margin-top: 54px;
  min-height: 90vh;
  background: #ffffff;

  +e(content) {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    padding: 0.9rem;

    ul {
      display: flex;
      flex-flow: row;
      flex-wrap: wrap;
      align-items: center;

      li {
        background: #33cc66;
        font-size: 14px;
        color: #ffffff;
        border-radius: 5rem;
        line-height: 26px;
        padding: 0 0.5rem;
        margin: 0 0.9rem 0.5rem 0;
        position: relative;
        min-width: 20px;

        .me-icon-close {
          color: red;
          position: absolute;
          right: -0.4rem;
          top: -0.5rem;
        }
      }
    }

    input {
      line-height: 26px;
      border-radius: 5rem;
      border: 1px solid #eeeeee;
      width: 80px;
      font-size: 14px;
      text-indent: 0.5rem;
      margin: 0 0.5rem 0.5rem 0;
    }
  }
}
</style>