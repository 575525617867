var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "me-tag-input" },
    [
      _c("Header", [
        _c("i", {
          staticClass: "me-icon-back",
          attrs: { slot: "left-one" },
          on: {
            click: function($event) {
              return _vm.$router.go(-1)
            }
          },
          slot: "left-one"
        }),
        _c("i", {
          staticClass: "me-icon-check",
          attrs: { slot: "right-one" },
          on: {
            click: function($event) {
              return _vm.handlerComplateTag()
            }
          },
          slot: "right-one"
        })
      ]),
      _c("div", { staticClass: "me-tag-input__content" }, [
        _vm.tags.length
          ? _c(
              "ul",
              [
                _vm._l(_vm.tags, function(tag, index) {
                  return [
                    tag.trim()
                      ? _c("li", { key: index }, [
                          _vm._v("\n        " + _vm._s(tag) + "\n        "),
                          _c("i", {
                            staticClass: "me-icon-close",
                            on: {
                              click: function($event) {
                                return _vm.handlerRemoveTag(index)
                              }
                            }
                          })
                        ])
                      : _vm._e()
                  ]
                })
              ],
              2
            )
          : _vm._e(),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.newTag,
              expression: "newTag"
            },
            {
              name: "focus",
              rawName: "v-focus",
              value: _vm.allwaysTrue,
              expression: "allwaysTrue"
            }
          ],
          ref: "tagInput",
          attrs: { type: "text" },
          domProps: { value: _vm.newTag },
          on: {
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.handlerCreateNewTag()
            },
            blur: function($event) {
              return _vm.handlerCreateNewTag()
            },
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.newTag = $event.target.value
            }
          }
        })
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }